* {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: #fff;
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #393d45;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  margin-top: 4rem;
}

.align-right {
  text-align: right;
}

input,
textarea {
  appearance: none;
  padding: 1rem;
  border: none;
  border-bottom: 0.1rem solid #fff;
  background: none;
  font-size: inherit;
}

input,
textarea {
  display: block;
  width: 100%;
  margin-top: 1rem;
}

input[type='submit'] {
  border: 0.1rem solid #fff;
  display: inline-block;
  width: auto;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  margin: 1rem 0 0;
}
